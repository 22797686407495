@font-face {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/IBMPlexSans/IBMPlexSans-Bold.otf) format("opentype");
}
@font-face {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    src: url(../fonts/IBMPlexSans/IBMPlexSans-Medium.otf) format("opentype");
}
@font-face {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/IBMPlexSans/IBMPlexSans-Light.otf) format("opentype");
}